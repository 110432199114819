import { I2goModalWorker } from './services/I2goModalWorker';

interface ModalOptions {
    [key: string]: any;
}

export function showI2goModal(modalName: string, options: ModalOptions) {
    const modalWorker = new I2goModalWorker();
    modalWorker.postMessage({ modalName, options: options ?? {} });
}
